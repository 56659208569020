body {
  font-family: "Cabin", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

img {
  vertical-align: middle;
  height: auto;
  max-width: 100%;
}
.form-control {
  text-align: center;
}
.preview-container {
  position: relative;
  .text-container {
    position: absolute;
    bottom: 6px;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .text {
      cursor: pointer;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      padding: 0.125rem;
      line-height: 1;
      display: block;
      border: 1px dotted rgba(255, 255, 255, 0.3);
      &:active {
        border: 1px dotted rgba(255, 255, 160, 0.9);
        color: rgba(255, 255, 160, 0.9);
        opacity: 0.66;
      }
    }
  }
}

.preview-img {
  position: relative;
  padding: 10px;
  img {
    position: relative;
  }
  &:after {
    position: absolute;
    content: "";
    top: 10px;
    bottom: 10%;
    left: 10px;
    z-index: 5;
    right: 10px;
    display: block;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(1px);
  }
  &:before {
    position: absolute;
    content: "";
    top: 10px;
    bottom: 10px;
    left: 10px;
    z-index: 4;
    right: 10px;
    display: block;
  }
}

.kartu {
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 55;
    content: "";
    display: block;
  }
  img {
    position: relative;
    z-index: -99;
    opacity: 0;
  }
}
